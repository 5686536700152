// Here you can add other styles
/* TILES */
.tile {
  width: 100%;
  float: left;
  margin: 0px;
  list-style: none;
  text-decoration: none;
  font-size: 38px;
  font-weight: 300;
  color: #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 0px;
  margin-bottom: 20px;
  min-height: 100px;
  position: relative;
  border: 1px solid #d5d5d5;
  text-align: center;
}
.tile.tile-valign {
  line-height: 75px;
}
.tile.tile-default {
  background: #fff;
  color: #656d78;
}
.tile.tile-default:hover {
  background: #fafafa;
}
.tile.tile-primary {
  background: #2f353a;
  border-color: #2f353a;
}
.tile.tile-primary:hover {
  background: #2f353a;
}
.tile.tile-success {
  background: #4dbd74;
  border-color: #4dbd74;
}
.tile.tile-success:hover {
  background: #4dbd74;
}
.tile.tile-warning {
  background: #ffc107;
  border-color: #ffc107;
}
.tile.tile-warning:hover {
  background: #ffc107;
}
.tile.tile-danger {
  background: #f86c6b;
  border-color: #f86c6b;
}
.tile.tile-danger:hover {
  background: #f86c6b;
}
.tile.tile-info {
  background: #63c2de;
  border-color: #63c2de;
}
.tile.tile-info:hover {
  background: #63c2de;
}
.tile:hover {
  text-decoration: none;
  color: #fff;
}
.tile.tile-default:hover {
  color: #656d78;
}
.tile .fa {
  font-size: 52px;
  line-height: 74px;
}
.tile p {
  font-size: 14px;
  margin: 0px;
}
.tile .informer {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 12px;
  color: #fff;
  line-height: 14px;
}
.tile .informer.informer-default {
  color: #fff;
}
.tile .informer.informer-primary {
  color: #33414e;
}
.tile .informer.informer-success {
  color: #95b75d;
}
.tile .informer.informer-info {
  color: #3fbae4;
}
.tile .informer.informer-warning {
  color: #fea223;
}
.tile .informer.informer-danger {
  color: #b64645;
}
.tile .informer .fa {
  font-size: 14px;
  line-height: 16px;
}
.tile .informer.dir-tr {
  left: auto;
  right: 5px;
}
.tile .informer.dir-bl {
  top: auto;
  bottom: 5px;
}
.tile .informer.dir-br {
  left: auto;
  top: auto;
  right: 5px;
  bottom: 5px;
}
/* EOF TILES */

/* PANEL */
.text-muted {
  color: #aaa;
  font-size: 11px;
}
/* EOF PANEL */

/* PAGINATION */

.react-bootstrap-table-pagination-list > ul {
  text-align: right;
}

/*CARD BUTTON*/
.card button {
  margin-right: 0.1rem;
}

.add-right-margin {
  margin-right: 0.1rem;
}

/* PROFILE PICTURE */

img.profile-picture {
  max-width: 200px;
  height: auto;
}

img.logo-survey {
  max-width: 150px;
  height: auto;
}

.hello-text {
  color: #656d78;
}

img.survey-attachment {
  max-width: 200px;
  height: 200px;
}
// .img-avatar {
//   max-width: 50px;
//   max-height: 50px;
// }

.center-text {
  text-align: center;
}
